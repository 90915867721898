import axios from "axios";
import {
  setActualMood,
  setMoods,
  setPlaylist,
  toggleError,
} from "../redux/playerSlice";

const apiUrl = "https://api.helloyouartist.com";

function setMoodSearchParam(mood, searchParams, setSearchParams) {
  let songParam = searchParams.get("song");

  if (songParam === null) {
    setSearchParams({ mood: mood.id });
  } else {
    setSearchParams({ mood: mood.id, song: songParam });
  }
}

function setDefaultMood(moods, searchParams, setSearchParams, dispatch) {
  for (const mood of moods) {
    if (mood.default === true) {
      dispatch(setActualMood(mood));
      setMoodSearchParam(mood, searchParams, setSearchParams);
      return mood;
    }
  }
}

function setParamMood(moods, searchParams, setSearchParams, dispatch) {
  for (const mood of moods) {
    if (mood.id === searchParams.get("mood")) {
      dispatch(setActualMood(mood));
      return mood;
    }
  }
  return setDefaultMood(moods, searchParams, setSearchParams, dispatch);
}

function setSongParamAtFirst(
  newPlaylist,
  songParam,
  playlistMood,
  setSearchParams,
  dispatch
) {
  let songParamIndex = newPlaylist.findIndex(
    (song) => song.id === parseInt(songParam)
  );
  let firstSong = null;

  if (songParamIndex === -1) {
    dispatch(setPlaylist(newPlaylist));
    setSearchParams({ mood: playlistMood.id, song: newPlaylist[0].id });
    return newPlaylist;
  } else {
    firstSong = newPlaylist.splice(songParamIndex, 1);
    newPlaylist.unshift(firstSong[0]);
    dispatch(setPlaylist(newPlaylist));
  }
}

const loadPlaylist = (
  playlist,
  playlistMood,
  searchParams,
  setSearchParams,
  dispatch
) => {
  let songParam = searchParams.get("song");

  let newPlaylist = playlist.sort((a, b) => 0.5 - Math.random());

  if (songParam === null) {
    dispatch(setPlaylist(newPlaylist));
    setSearchParams({ mood: playlistMood.id, song: newPlaylist[0].id });
  } else {
    setSongParamAtFirst(
      newPlaylist,
      songParam,
      playlistMood,
      setSearchParams,
      dispatch
    );
  }
};

export const initPlaylist = (
  playlistMood,
  searchParams,
  setSearchParams,
  dispatch
) => {
  axios
    .get(apiUrl + "/clips?mood=" + playlistMood.id)
    .then((res) => {
      loadPlaylist(
        res.data,
        playlistMood,
        searchParams,
        setSearchParams,
        dispatch
      );
    })
    .catch((err) => {
      // console.log(err);
      dispatch(toggleError());
    });
};

const loadMoods = (moods, searchParams, setSearchParams, dispatch) => {
  let playlistMood = null;

  dispatch(setMoods(moods));
  if (searchParams.get("mood") === null) {
    playlistMood = setDefaultMood(
      moods,
      searchParams,
      setSearchParams,
      dispatch
    );
  } else {
    playlistMood = setParamMood(moods, searchParams, setSearchParams, dispatch);
  }
  initPlaylist(playlistMood, searchParams, setSearchParams, dispatch);
};

export function initData(searchParams, setSearchParams, dispatch) {
  axios
    .get(apiUrl + "/moods")
    .then((res) => {
      loadMoods(res.data, searchParams, setSearchParams, dispatch);
    })
    .catch((err) => {
      // console.log(err);
      dispatch(toggleError());
    });
}
