import React from "react";
import "./index.css";
import PauseButton from "./PauseButton.svg";
import PlayButton from "./PlayButton.svg";
import { windowsIndex } from "../../redux/windowsSlice";
import { useSelector, useDispatch } from "react-redux";
import { toggleMusic } from "../../redux/playerSlice";
import { BsFillTriangleFill } from "react-icons/bs";

export default function VideoController({
  songPlaylist,
  songIndex,
  onPreviousClick,
  onSkipClick,
}) {
  const dispatch = useDispatch();
  const aboutOpen = useSelector(
    (state) => state.windows.windows[windowsIndex.about].open
  );
  const actualMood = useSelector((state) => state.player.actualMood);
  const playing = useSelector((state) => state.player.playing);
  return (
    <div className="video-player-controller-container">
      {!aboutOpen && (
        <>
          <span
            style={{
              transform:
                songPlaylist[songIndex].artist.length +
                  songPlaylist[songIndex].name.length >=
                40
                  ? "scale(0.7)"
                  : "scale(1)",
            }}
            className="video-player-controller-title"
          >
            {songPlaylist[songIndex].artist} - {songPlaylist[songIndex].name}
          </span>
          <div className="video-player-controller-controls">
            <BsFillTriangleFill
              className={"video-player-controller-control change-left"}
              onClick={onPreviousClick}
              alt="Previous Song"
              color={actualMood !== null ? actualMood.color : "black"}
            />
            <img
              className="video-player-controller-control"
              onClick={() => dispatch(toggleMusic())}
              src={playing ? PauseButton : PlayButton}
              style={{ scale: playing ? "1" : "1.3" }}
              alt="Previous Song"
            />
            <BsFillTriangleFill
              className={"video-player-controller-control change-right"}
              onClick={onSkipClick}
              alt="Previous Song"
              color={actualMood !== null ? actualMood.color : "black"}
            />
          </div>
        </>
      )}
    </div>
  );
}
