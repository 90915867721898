import { createSlice } from "@reduxjs/toolkit";

export const windowsIndex = {
  about: 0,
  contribute: 1,
  moodPicker: 2,
};

const initialState = {
  windows: [
    {
      name: "about",
      open: false,
    },
    {
      name: "contribute",
      open: false,
    },
    {
      name: "moodPicker",
      open: false,
    },
  ],
};

export const windowsSlice = createSlice({
  name: "windows",
  initialState,
  reducers: {
    toggleByName: (state, action) => {
      let windows = state.windows;

      windows.forEach((window) => {
        if (window.name === action.payload) {
          window.open = !window.open;
        } else {
          window.open = false;
        }
      });

      state.windows = windows;
    },
  },
});

export const { toggleByName } = windowsSlice.actions;

export default windowsSlice.reducer;
