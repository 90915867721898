import React from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  setMute,
  setVolume,
  toggleVolumeSlider,
} from "../../redux/playerSlice";
import "./index.css";
import VolumeOn from "./VolumeOnPNG.png";
import VolumeOff from "./VolumeOffPNG.png";

const default_desc = "FOUNDER @karl | DEV @tom";

export default function UserDescription() {
  const actualMood = useSelector((state) => state.player.actualMood);
  const actualMusic = useSelector((state) => state.player.actualMusic);
  const volume = useSelector((state) => state.player.volume);
  const volumeSliderOpen = useSelector(
    (state) => state.player.volumeSliderOpen
  );
  const muted = useSelector((state) => state.player.muted);
  const dispatch = useDispatch();

  return (
    <div className="user-description-container">
      <div
        className="user-decription-mood-container"
        style={{
          backgroundColor: actualMood !== null ? actualMood.color : "#BE83E2",
          justifyContent: volumeSliderOpen === true ? "flex-end" : "center",
        }}
        onMouseEnter={() => {
          if (volumeSliderOpen) dispatch(toggleVolumeSlider(true));
        }}
        onMouseLeave={() => dispatch(toggleVolumeSlider(false))}
      >
        <img
          src={volume == 0 || muted === true ? VolumeOff : VolumeOn}
          alt="Volume On"
          className={
            volume == 0 || muted === true
              ? "user-description-sound-button off"
              : "user-description-sound-button on"
          }
          onMouseEnter={() => dispatch(toggleVolumeSlider(true))}
          onClick={() => dispatch(setMute(!muted))}
        />
        {volumeSliderOpen ? (
          <input
            className="user-description-sound-slider"
            type="range"
            min="0"
            max="100"
            value={muted ? 0 : volume}
            onChange={(e) => dispatch(setVolume(e.target.value))}
            step="10"
          />
        ) : (
          <span className="user-description-mood">
            {"#" + (actualMood !== null ? actualMood.name : "hya")}
          </span>
        )}
      </div>
      <div
        className="user-description-text-container"
        style={{
          backgroundColor:
            actualMood !== null ? actualMood.color + "30" : "#BE83E230",
        }}
      >
        <span className="user-description-text">
          {actualMusic !== null
            ? actualMusic.sender + " - " + actualMusic.description
            : default_desc}
        </span>
      </div>
    </div>
  );
}
