import React, { useState, useEffect } from "react";
import HyaLogo from "../About/HyaLogo.svg";
import "./index.css";
import { useForm } from "react-hook-form";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { toggleByName, windowsIndex } from "../../redux/windowsSlice";
import InstagramLogo from "../Footer/InstagramLogo.svg";
import TiktokLogo from "../Footer/TiktokLogo.svg";
import { useTranslation } from "react-i18next";

export default function Contribute() {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      url: "",
      description: "",
      mood: "none",
      sender: "",
    },
  });

  const [error, setError] = useState(false);
  const [success, setSuccess] = useState(false);

  const open = useSelector(
    (state) => state.windows.windows[windowsIndex.contribute].open
  );

  const moods = useSelector((state) => state.player.moods);

  const dispatch = useDispatch();

  const [isOpen, setOpen] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);

  const { t } = useTranslation();

  const closeContribute = () => {
    setFadeOut(true);
    setTimeout(() => {
      setOpen(false);
      setFadeOut(false);
    }, 300);
  };

  useEffect(() => {
    if (open === true) setOpen(true);
    if (open === false) closeContribute();
  }, [open]);

  const onSubmit = (data) => {
    axios
      .post("https://api.helloyouartist.com/clips/", data)
      .then((res) => {
        reset();
        dispatch(toggleByName("home"));
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  };

  if (!isOpen) return;

  return (
    <>
      <div className={"contribute-background " + (fadeOut ? "fade-out" : "")} />
      <div className={"contribute-container" + (fadeOut ? "fade-out" : "")}>
        <img
          className="contribute-hya-text-img"
          src={HyaLogo}
          alt="Hya Logo Text"
        />
        <span className="contribute-subtitle">{t("contribute-subtitle")}</span>
        <form
          className="contribute-input-container"
          onSubmit={handleSubmit(onSubmit)}
        >
          <input
            type="text"
            name="url"
            placeholder={t("contribute-url-label")}
            className="contribute-input"
            autoComplete="off"
            {...register("url", { required: true })}
          />
          <textarea
            type="text"
            name="description"
            placeholder={t("contribute-description-label")}
            className="contribute-input"
            autoComplete="off"
            style={{ resize: "none" }}
            {...register("description", { required: true })}
          />
          <select
            name="mood"
            id="moods"
            className="contribute-input"
            placeholder="Select a mood"
            {...register("mood", { required: true })}
          >
            <option disabled select value="none">
              Select a mood
            </option>
            {moods.map((mood) => (
              <option key={mood.id} value={mood.id}>
                {mood.name}
              </option>
            ))}
          </select>
          <input
            type="text"
            name="description"
            placeholder={t("contribute-social-media-label")}
            className="contribute-input"
            autoComplete="off"
            {...register("sender", { required: true })}
          />
          <input
            value={t("contribute-submit-button")}
            type="submit"
            className="contribute-submit-button"
          />
          {(errors.url ||
            errors.mood ||
            errors.description ||
            errors.sender) && (
            <span className="contribute-error-msg">
              Tu dois remplir tous les champs!
            </span>
          )}
          {success && (
            <span className="contribute-error-msg">
              Merci pour ta contribution!
            </span>
          )}
          {error && (
            <span className="contribute-error-msg">
              Vérifie bien les champs!
            </span>
          )}
        </form>
        <div className="contribute-social-media-container">
          <a
            href="https://www.instagram.com/helloyouartist/"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="contribute-social-media-logo"
              src={InstagramLogo}
              alt="Instagram Logo"
            />
          </a>
          <a
            href="https://www.tiktok.com/@helloyouartist"
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              className="contribute-social-media-logo"
              src={TiktokLogo}
              alt="Titok Logo"
            />
          </a>
        </div>
      </div>
    </>
  );
}
