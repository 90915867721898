import React, { useEffect } from "react";
import "./index.css";
import ErrorScreen from "../ErrorScreen";
import VideoPlayer from "../../components/VideoPlayer";
import Navbar from "../../components/Navbar";
import MoodPicker from "../../components/MoodPicker";
import Footer from "../../components/Footer";
import About from "../../components/About";
import Contribute from "../../components/Contribute";
import { useDispatch, useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { initData, initPlaylist } from "../../api/getPlaylistMoodApi";

export default function HomeScreen() {
  const playlist = useSelector((state) => state.player.playlist);
  const actualMood = useSelector((state) => state.player.actualMood);
  const moods = useSelector((state) => state.player.moods);
  const errorApi = useSelector((state) => state.player.errorApi);

  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  useEffect(() => {
    initData(searchParams, setSearchParams, dispatch);
  }, []);

  useEffect(() => {
    if (actualMood !== null)
      initPlaylist(actualMood, searchParams, setSearchParams, dispatch);
  }, [actualMood]);

  if (playlist === null || moods === null) return <div>LOADING</div>;

  return (
    <div className="home-container">
      <Navbar />
      {errorApi === true ? (
        <ErrorScreen />
      ) : (
        <>
          <Contribute />
          <MoodPicker />
          <VideoPlayer />
          <About />
        </>
      )}
      <Footer />
    </div>
  );
}
