import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import "./index.css";
import VideoController from "../VideoController";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { setActualMusicByIndex, toggleMusic } from "../../redux/playerSlice";
import { windowsIndex } from "../../redux/windowsSlice";

export default function VideoPlayer() {
  const [songIndex, setSongIndex] = useState(0);
  const [searchParams, setSearchParams] = useSearchParams();

  const dispatch = useDispatch();

  const playlist = useSelector((state) => state.player.playlist);
  const playing = useSelector((state) => state.player.playing);
  const volume = useSelector((state) => state.player.volume);
  const isAboutOpen = useSelector(
    (state) => state.windows.windows[windowsIndex.about].open
  );
  const muted = useSelector((state) => state.player.muted);

  const setSongParam = (songIndex) => {
    setSearchParams({
      mood: searchParams.get("mood"),
      song: playlist[songIndex].id,
    });
    dispatch(setActualMusicByIndex(songIndex));
  };

  useEffect(() => {
    setSongIndex(0);
  }, [searchParams.get("mood")]);

  const skipMusic = () => {
    if (songIndex === playlist.length - 1) {
      setSongIndex(0);
      setSongParam(0);
    } else {
      setSongIndex(songIndex + 1);
      setSongParam(songIndex + 1);
    }
    if (!playing) dispatch(toggleMusic());
  };

  const previousMusic = () => {
    if (songIndex === 0) {
      setSongIndex(playlist.length - 1);
      setSongParam(playlist.length - 1);
    } else {
      setSongIndex(songIndex - 1);
      setSongParam(songIndex - 1);
    }
    if (!playing) dispatch(toggleMusic());
  };

  if (playlist.length === 0) return <div>LOADING</div>;

  return (
    <div className="video-player-container">
      <div
        className="video-player-protection"
        style={{ backgroundColor: isAboutOpen ? "#00000099" : "#00000035" }}
      />
      <ReactPlayer
        className="player"
        url={playlist[songIndex].url}
        height="300%"
        width="100%"
        playing={playing}
        pip={true}
        onEnded={skipMusic}
        volume={volume / 100}
        muted={muted}
      />
      <VideoController
        songPlaylist={playlist}
        songIndex={songIndex}
        onPreviousClick={previousMusic}
        onSkipClick={skipMusic}
      />
    </div>
  );
}
