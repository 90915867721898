import React from "react";
import "./index.css";
import HyaLogo from "./HyaLogo.svg";
import "../../fonts/sf-ui-display-cufonfonts-webfont/style.css";
import Button from "../Button";
import { toggleByName, windowsIndex } from "../../redux/windowsSlice";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import Languages from "../Languages";

export default function Navbar() {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const moodPickerOpen = useSelector(
    (state) => state.windows.windows[windowsIndex.moodPicker].open
  );
  const contributeOpen = useSelector(
    (state) => state.windows.windows[windowsIndex.contribute].open
  );

  return (
    <div className="navbar-wrapper">
      <div className="navbar-container">
        <div className="navbar-item left">
          <Button
            title="MOOD"
            onClick={() => dispatch(toggleByName("moodPicker"))}
            isClicked={moodPickerOpen}
          />
        </div>
        <div className="navbar-item">
          <img
            className="navbar-logo"
            src={HyaLogo}
            alt="HELLO YOU ARTIST"
            onClick={() => dispatch(toggleByName("home"))}
          />
        </div>
        <div className="navbar-item right">
          <Button
            title={t("contribute-button-title")}
            onClick={() => dispatch(toggleByName("contribute"))}
            isClicked={contributeOpen}
          />
        </div>
      </div>
      <Languages />
    </div>
  );
}
