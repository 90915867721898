import React, { useState, useEffect } from "react";
import { useSearchParams } from "react-router-dom";
import "./index.css";
import { useSelector, useDispatch } from "react-redux";
import { windowsIndex, toggleByName } from "../../redux/windowsSlice";
import { setActualMood, toggleMusic } from "../../redux/playerSlice";
import { useTranslation } from "react-i18next";

export default function MoodPicker() {
  const { i18n } = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const [isOpen, setOpen] = useState(false);
  const [fadeOut, setFadeOut] = useState(false);
  const dispatch = useDispatch();
  const open = useSelector(
    (state) => state.windows.windows[windowsIndex.moodPicker].open
  );
  const moods = useSelector((state) => state.player.moods);
  const playing = useSelector((state) => state.player.playing);

  const onSetActualMood = (e) => {
    let moodId = e.target.getAttribute("name");
    dispatch(
      setActualMood(moods[moods.findIndex((mood) => mood.id === moodId)])
    );
    setSearchParams({ mood: moodId, song: searchParams.get("song") });
    if (!playing) dispatch(toggleMusic());
    dispatch(toggleByName("moodPicker"));
  };

  const closeMoodPicker = () => {
    setFadeOut(true);
    setTimeout(() => {
      setOpen(false);
      setFadeOut(false);
    }, 300);
  };

  useEffect(() => {
    if (open === true) setOpen(true);
    if (open === false) closeMoodPicker();
  }, [open]);

  if (!isOpen) return;

  if (moods.length === 0) return <div>Loading...</div>;

  return (
    <>
      <div
        className={"mood-transparent-background " + (fadeOut ? "fade-out" : "")}
        onClick={() => dispatch(toggleByName("moodPicker"))}
      />
      <div className={"mood-picker-container " + (fadeOut ? "fade-out" : "")}>
        {moods.map((mood) => (
          <img
            className="mood-picker-cover-img "
            name={mood.id}
            key={mood.id}
            alt={mood.name}
            src={
              i18n.language === "fr" ? mood.cover_image_fr : mood.cover_image
            }
            onClick={onSetActualMood}
          />
        ))}
      </div>
      {/* </div> */}
    </>
  );
}
