import React from "react";
import { useDispatch, useSelector } from "react-redux";
import "./index.css";
import InstagramLogo from "./InstagramLogo.svg";
import TiktokLogo from "./TiktokLogo.svg";
import { toggleByName, windowsIndex } from "../../redux/windowsSlice";
import UserDescription from "../UserDescription";
import { useTranslation } from "react-i18next";

var initialDate = new Date(2022, 8, 11); // Attention: month is zero-based
var now = Date.now();
var difference = now - initialDate;
var millisecondsPerDay = 24 * 60 * 60 * 1000;
var daysSince = Math.floor(difference / millisecondsPerDay);

function getRndInteger(min, max) {
  return Math.floor(Math.random() * (max - min)) + min;
}

export default function Footer() {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isAboutOpen = useSelector(
    (state) => state.windows.windows[windowsIndex.about].open
  );
  const actualMood = useSelector((state) => state.player.actualMood);
  const activeUsersRange = t("active-users-range");

  const [fakeActiveUsers, setFakeActiveUsers] = React.useState(50);

  console.log(activeUsersRange);

  React.useEffect(() => {
    if (activeUsersRange === "none" || activeUsersRange === "rien") {
      setFakeActiveUsers(30 + 5 * daysSince + getRndInteger(0, 20));
    } else {
      setFakeActiveUsers(
        getRndInteger(
          parseInt(activeUsersRange.split(",")[0]),
          parseInt(activeUsersRange.split(",")[1])
        )
      );
    }
  }, []);

  return (
    <div className="footer-container">
      <span
        className="footer-about-button"
        data-text="à propos"
        onClick={() => dispatch(toggleByName("about"))}
        style={isAboutOpen ? { color: "#be83e2", zIndex: 10000 } : null}
      >
        {t("about-button-title")}
      </span>
      <span className="live-user-counter">
        <span
          className="live-user-counter-dot"
          style={{
            color: actualMood !== null ? actualMood.color : "black",
            animation: "blinker 1s linear infinite",
          }}
        >
          {"●"}
        </span>
        {"PLAYING"}
      </span>
      <div className="footer-social-media-container">
        <a
          href="https://www.instagram.com/helloyouartist/"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="footer-social-media-logo"
            src={InstagramLogo}
            alt="Instagram Logo"
          />
        </a>
        <a
          href="https://www.tiktok.com/@helloyouartist"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="footer-social-media-logo"
            src={TiktokLogo}
            alt="Titok Logo"
          />
        </a>
      </div>
      <UserDescription />
    </div>
  );
}
