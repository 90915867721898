import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  playlist: [],
  moods: [],
  errorApi: false,
  actualMood: null,
  actualMusic: null,
  playing: false,
  volume: 100,
  volumeSliderOpen: false,
  muted: false,
  language: "en",
};

export const playerSlice = createSlice({
  name: "player",
  initialState,
  reducers: {
    toggleMusic: (state) => {
      state.playing = !state.playing;
    },
    setPlaylist: (state, action) => {
      state.playlist = action.payload;
      state.actualMusic = action.payload[0];
    },
    setMoods: (state, action) => {
      state.moods = action.payload;
    },
    setActualMood: (state, action) => {
      state.actualMood = action.payload;
    },
    setActualMusicByIndex: (state, action) => {
      state.actualMusic = state.playlist[action.payload];
    },
    toggleError: (state) => {
      state.errorApi = true;
    },
    setVolume: (state, action) => {
      state.volume = action.payload;
    },
    toggleVolumeSlider: (state, action) => {
      state.volumeSliderOpen = action.payload;
    },
    setMute: (state, action) => {
      state.muted = action.payload;
    },
    setLanguage: (state, action) => {
      state.language = action.payload;
    },
  },
});

export const {
  toggleMusic,
  setPlaylist,
  setMoods,
  setActualMood,
  setActualMusicByIndex,
  toggleError,
  setVolume,
  toggleVolumeSlider,
  setMute,
  setLanguage,
} = playerSlice.actions;

export default playerSlice.reducer;
