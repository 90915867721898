import React from "react";
import "./index.css";

export default function Button({
  title,
  color,
  variant,
  width,
  padding,
  onClick,
  isClicked,
}) {
  const [isLocalClicked, setIsLocalClicked] = React.useState(false);

  const onButtonClick = () => {
    onClick();
  };

  const onVariantClick = () => {
    onClick();
    setIsLocalClicked(true);
  };

  if (variant == "second")
    return (
      <span
        style={{
          backgroundColor: "#BE83E2",
          border: "none",
          fontFamily: "SF UI Display Bold",
          fontSize: "20px",
          color: isLocalClicked ? "white" : color,
          padding: padding,
        }}
        className="button second"
        onClick={onVariantClick}
      >
        {title}
      </span>
    );
  return (
    <span
      className={"button-primary " + (isClicked ? "clicked" : "unclicked")}
      onClick={onButtonClick}
    >
      {title}
    </span>
  );
}
