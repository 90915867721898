import React from "react";
import "./index.css";

export default function ErrorScreen() {
  return (
    <div className="error-screen">
      <svg
        width="224"
        height="47"
        viewBox="0 0 224 47"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M174.692 1.10059H195.383C203.322 15.9037 211.868 30.404 221.019 44.5343L221.793 45.7454H199.723L196.19 38.1084H173.851L170.318 45.7454H148.248L148.988 44.6016C158.173 30.4377 166.752 15.9373 174.692 1.10059ZM178.931 27.2079H191.177L185.054 14.0197L178.931 27.2079Z"
          stroke="white"
          stroke-width="2"
          stroke-miterlimit="10"
          class="svg-elem-1"
        ></path>
        <path
          d="M117.228 17.0817L130.316 1.06738H150.973L145.993 6.01297C138.491 13.4818 131.762 21.6572 125.908 30.4717V32.7932C125.908 37.1332 126.278 41.4395 126.985 45.7122H107.472C108.178 41.4395 108.548 37.1332 108.548 32.7932V30.4717C102.661 21.6235 95.8983 13.4145 88.3622 5.91203L83.4839 1.06738H104.175L117.228 17.0817Z"
          stroke="white"
          stroke-width="2"
          stroke-miterlimit="10"
          class="svg-elem-2"
        ></path>
        <path
          d="M21.8833 30.4044V45.6448H2C3.64853 30.8754 3.64853 15.9713 2.03364 1.16822L2 1H21.8833V16.2405H48.0242V1H67.9412V1.13458C66.2926 15.9041 66.3263 30.8417 67.9412 45.6112H48.0242V30.3707H21.8833V30.4044Z"
          stroke="white"
          stroke-width="2"
          stroke-miterlimit="10"
          class="svg-elem-3"
        ></path>
      </svg>
      <span className="error-screen-text">
        connexion au serveur impossible.
        <br />
        veuillez réessayer dans quelques minutes.
      </span>
    </div>
  );
}
