import React, { useState, useEffect } from "react";
import "./index.css";
import Waves from "./Waves.svg";
import HyaLogo from "./HyaLogo.svg";
import { useDispatch, useSelector } from "react-redux";
import { windowsIndex, toggleByName } from "../../redux/windowsSlice";
import { useTranslation } from "react-i18next";

export default function About() {
  const [fadeOut, setFadeOut] = useState(false);
  const [isOpen, setOpen] = useState(false);
  const dispatch = useDispatch();
  const open = useSelector(
    (state) => state.windows.windows[windowsIndex.about].open
  );
  const { t } = useTranslation();

  const closeAbout = () => {
    setFadeOut(true);
    setTimeout(() => {
      setOpen(false);
      setFadeOut(false);
    }, 300);
  };

  useEffect(() => {
    if (open === true) setOpen(true);
    if (open === false) closeAbout();
  }, [open]);

  if (!isOpen) return;

  return (
    <>
      <div
        className={"about-container " + (fadeOut ? "fade-out" : "")}
        onClick={() => dispatch(toggleByName("about"))}
      >
        <img className="about-hya-text-img" src={HyaLogo} alt="Hya Logo Text" />
        <div className="about-text-wrapper">
          <div className="about-text-container">
            <img src={Waves} alt="Waves" className="about-text-img" />
            <span className="about-text-title">HELLO</span>
            <p>{t("about-hello")}</p>
            <span className="about-text-title">YOU</span>
            <p>{t("about-you")}</p>
            <span className="about-text-title">ARTIST</span>
            <p>{t("about-artist")}</p>
          </div>
        </div>
      </div>
    </>
  );
}
