import React from "react";
import "./index.css";
import HYASplash from "./HyaText.svg";
import HYAPlanetOnly from "./HyaPlanetOnly.svg";
import HYAPlanetText from "./HyaPlanetText.svg";
import Button from "../../components/Button";
import { useDispatch } from "react-redux";
import { toggleMusic } from "../../redux/playerSlice";
import { useTranslation } from "react-i18next";
import Languages from "../../components/Languages";

export default function SplashScreen() {
  const [fadeOut, setFadeOut] = React.useState(false);
  const [isOpen, setOpen] = React.useState(true);
  const [canSkip, setCanSkip] = React.useState(false);

  const { t } = useTranslation();

  const dispatch = useDispatch();

  const closeSplashScreen = () => {
    if (!canSkip) return;
    setFadeOut(true);
    dispatch(toggleMusic());
    setTimeout(() => {
      setOpen(false);
      setFadeOut(false);
    }, 300);
  };

  React.useEffect(() => {
    setTimeout(() => {
      setCanSkip(true);
    }, 4000);
  });

  if (!isOpen) return;

  return (
    <div className={"splashscreen-container " + (fadeOut ? "fade-out" : "")}>
      <img
        className="splashcreen-hya-img first-text"
        src={HYASplash}
        alt="HELLO YOU ARTIST"
      />
      <img
        className="splashcreen-hya-img first-text"
        src={HYASplash}
        alt="HELLO YOU ARTIST"
      />
      <span className="splashcreen-hya-img first-text first-text-style">
        {t("splashscreen-first-subtitle")}
      </span>
      <img
        className="splashcreen-hya-img planet"
        src={HYAPlanetOnly}
        alt="HELLO YOU ARTIST"
      />
      <img
        className="splashcreen-hya-planet-text"
        src={HYAPlanetText}
        alt="HELLO YOU ARTIST"
      />
      <span className="splashcreen-click-text">
        <Languages />
        {t("splashscreen-second-subtitle")}
      </span>
      <div className="splashscreen-start-btn">
        <Button
          title={t("splashscreen-start-button")}
          variant="second"
          color="#000"
          width="150px"
          padding="15px 75px"
          onClick={closeSplashScreen}
        />
      </div>
    </div>
  );
}
