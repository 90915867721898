import React from "react";

import "./index.css";
import { useTranslation } from "react-i18next";

export default function Languages() {
  const { i18n } = useTranslation();

  console.log(i18n.language);

  const onLanguageClick = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <div className="languages-container">
      <span
        className="language"
        style={{ fontWeight: i18n.language === "en" ? "bold" : "normal" }}
        onClick={() => onLanguageClick("en")}
      >
        EN
      </span>
      {" | "}
      <span
        className="language"
        style={{ fontWeight: i18n.language === "fr" ? "bold" : "normal" }}
        onClick={() => onLanguageClick("fr")}
      >
        FR
      </span>
    </div>
  );
}
