import React from "react";
import "./App.css";
import SplashScreen from "./pages/SplashScreen";
import HomeScreen from "./pages/HomeScreen";

function App() {
  return (
    <div className="App">
      <SplashScreen />
      <HomeScreen />
    </div>
  );
}

export default App;
